(function($) {

	$.datepicker.setDefaults({
		showOn: "both",
		buttonImageOnly: true,
		buttonImage: "/images/samal/calendar.png",
		buttonText: "Календарь",
		regional: "ru",
		altFormat: "yymmdd",
		dateFormat: "dd.mm.yy"
	});

	$('[data-toggle="tooltip"]').tooltip();


	$('#myAffix').affix({
		offset: {
			top: 10,
			bottom: function () {
				return (this.bottom = $('.footer').outerHeight(true))
			}
		}
	});

	$.ajaxSetup({
		headers: {
			'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
		}
	});


})(jQuery);


/**
 * Класс помогающий формировать набор параметроы для ajax-запросов
 * @constructor
 */
function UrlRequest() {
	var _self = this;
	this.params = [];
	this.toString = function () {
		var parts = [];
		if (!_self.params.length) {
			return '';
		}
		_self.params.forEach(function (param) {
			parts.push(param.name + '=' + param.value);
		});
		return '?' + parts.join('&');
	}
}
/**
 * Класс параметр, используемый в классе Request
 * @param key
 * @param value
 * @constructor
 */
function UrlParam(key, value) {
	this.name = key;
	this.value = value;
}

