var helper = {

	numerable : function (number, for1, for2to4, forMore) {
		var numberToString = number.toString(),
			pos = numberToString.length,
			units = numberToString.substring(pos - 1),
			tens = 0,
			result = null;
		if (numberToString.length > 1) {
			tens = numberToString.substring(pos - 2);
		}
		switch (parseInt(units)) {
			case 1:
				if (tens && tens == 1)return forMore;
				result = for1;
				break;
			case 2:
				if (tens && tens == 1)return forMore;
				result = for2to4;
				break;
			case 3:
				if (tens && tens == 1)return forMore;
				result = for2to4;
				break;
			case 4:
				if (tens && tens == 1)return forMore;
				result = for2to4;
				break;
			default:
				result = forMore;
		}

		return result;
	},

	showMessage : function(type, message) {
		var messageBlock = $('#js-message');
		messageBlock.removeClass();
		messageBlock.addClass('message-block ' + type);
		messageBlock.text(message);
	},

	leftZero : function (s) {
		var t = String(s);
		return t.length == 1 ? "0"+t : t;
	}


};
